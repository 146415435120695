import "./ProductList.css";
import CopyDiv from "../CopyDiv/CopyDiv.js";

// import RenderIfVisible from "react-render-if-visible";
// import generateTitle from "../../util/generateTitle";

import calcPrice from "../../util/calcPrice";
import Loader from "../Loader/Loader.js";

function ProductList(props) {
    // console.log("teste");
    return (
        <div className="product-center">
            {!props.products?.length ? <Loader /> : <></>}
            <div className="product-list align-center">
                {props.products?.map((product, index) => (
                    // <RenderIfVisible
                    //     defaultHeight={300}
                    //     visibleOffset={2000}
                    // >
                    <ProductCard
                        setModalBurn={props.setModalBurn}
                        setModalBox={props.setModalBox}
                        setModalOption={props.setModalOption}
                        percentual={props.percentual}
                        lucro={props.lucro}
                        setImgSelected={props.setImgSelected}
                        setModalObj={props.setModalObj}
                        setModalProduct={props.setModalProduct}
                        setModalPrice={props.setModalPrice}
                        setModal={props.setModal}
                        product={product}
                        index={index}
                        length={props.products.length}
                        setSelected={props.setSelected}
                        selected={props.selected}
                        boxes={props.boxes}
                        key={product.id}
                    />
                    // </RenderIfVisible>
                ))}
            </div>
        </div>
    );
}

function ProductCard(props) {
    let hasId = props.selected.map((e) => e.id).includes(props.product.id);
    let obj = props.selected.filter((e) => e.id == props.product.id)[0];

    return (
        <div
            className={"product-card-container " + (hasId ? "selected" : "")}
            style={{ zIndex: props.length - props.index }}
            key={props.product.id}
        >
            <div
                className="product-card"
                key={props.product.id}
                style={{
                    animationDelay: `${Math.min(props.index * 20, 1000)}ms`,
                }}
            >
                {hasId ? (
                    <div className="product-card-back">
                        {props.product.box ? (
                            <div
                                className={`option ${obj && obj.options && obj.options.box ? "selected" : ""}`}
                                onClick={() => {
                                    let newSelected = props.selected.slice();
                                    let product = newSelected[newSelected.findIndex((e) => e.id == props.product.id)];

                                    if (!product.options.box) {
                                        product.options.box = true;
                                        product.options.boxQtty = product.qtde;
                                    } else {
                                        delete product.options.box;
                                        delete product.options.boxQtty;
                                    }

                                    props.setSelected(newSelected);
                                    console.log(newSelected);
                                }}
                            >
                                {/* {props.product.type == 1 ? (
                                    <img src="/img/case.svg" />
                                ) : (
                                    <img src="/img/caixa.svg" />
                                )} */}

                                <img src={`/uploads/internal/${props.boxes.find((e) => e.id == props.product.box)?.icon}`} />

                                <div className="box-price">
                                    <small>R$</small>{" "}
                                    {((obj && obj.options && obj.options.boxQtty) || obj.qtde) *
                                        props.boxes.find((e) => e.id == props.product.box)?.price || 5}
                                </div>
                                <img
                                    src="/img/config-icon.svg"
                                    className={`option-config ${
                                        (hasId ? props.selected.filter((e) => e.id == props.product.id)[0].qtde : 0) > 1
                                            ? "show"
                                            : ""
                                    }`}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        props.setModalObj(
                                            props.selected[props.selected.findIndex((e) => e.id == props.product.id)]
                                        );
                                        props.setModalBox(true);
                                    }}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {props.product.box ? (
                            <div className="box-popup">
                                <div className="box-popup-text">{props.boxes.find((e) => e.id == props.product.box)?.text}</div>
                                <img src={`/uploads/internal/${props.boxes.find((e) => e.id == props.product.box)?.photo}`} />
                            </div>
                        ) : (
                            ""
                        )}

                        {/*<div className='option'><img src="/img/case.svg"/></div>*/}
                        <div
                            className={`option gift ${obj && obj.options && obj.options.gift ? "selected" : ""}`}
                            onClick={() => {
                                let newSelected = props.selected.slice();
                                newSelected[newSelected.findIndex((e) => e.id == props.product.id)].options.gift = obj.options
                                    .gift
                                    ? !obj.options.gift
                                    : true;
                                newSelected[newSelected.findIndex((e) => e.id == props.product.id)].options.globalGift = 1;
                                props.setSelected(newSelected);
                            }}
                        >
                            <img src="/img/presente.svg" />
                            <div className="box-price">
                                <small>R$</small> {obj && obj.options && obj.options.globalGift == 1 ? 2 : obj && obj.qtde * 2}
                            </div>
                            <img
                                src="/img/config-icon.svg"
                                className={`option-config ${
                                    (hasId ? props.selected.filter((e) => e.id == props.product.id)[0].qtde : 0) > 1 ? "show" : ""
                                }`}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    props.setModalObj(props.selected[props.selected.findIndex((e) => e.id == props.product.id)]);
                                    props.setModalOption(true);
                                }}
                            />
                        </div>
                        <div className="box-popup">
                            <div className="box-popup-text">
                                Faça a diferença na experiencia do seu cliente anexando uma embalagem de presente!
                            </div>
                            <img src="/img/presente3.png" />
                        </div>
                        {props.product.custom ? (
                            <div
                                className={`option ${obj?.options?.custom ? "selected" : ""}`}
                                onClick={() => {
                                    let newSelected = props.selected.slice();
                                    let product = newSelected[newSelected.findIndex((e) => e.id == props.product.id)];

                                    if (!product.options.custom) {
                                        product.options.custom = true;
                                        product.options.names = [];
                                        props.setModalObj(product);
                                        props.setModalBurn(true);
                                    } else {
                                        delete product.options.custom;
                                        delete product.options.names;
                                    }

                                    props.setSelected(newSelected);
                                    console.log(newSelected);
                                }}
                            >
                                {/* {props.product.type == 1 ? (
                                    <img src="/img/case.svg" />
                                ) : (
                                    <img src="/img/caixa.svg" />
                                )} */}

                                <img className="img-custom" src={`/img/custom.svg`} />

                                <img
                                    src="/img/config-icon.svg"
                                    className={`option-config ${hasId ? "show" : ""}`}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        props.setModalObj(
                                            props.selected[props.selected.findIndex((e) => e.id == props.product.id)]
                                        );
                                        props.setModalBurn(true);
                                    }}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {props.product.custom ? (
                            <div className={`box-popup burn-popup`}>
                                <div className="box-popup-text">
                                    Personalize a gravação do produto de acordo com o pedido do seu cliente!
                                </div>
                                <img src={`/img/custom3.png`} />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
                <img
                    className="product-photo"
                    src={"https://orizom.com.br/uploads/thumbs/" + props.product.photos[0]}
                    onClick={() => {
                        props.setModalObj({ ...props.product });
                        props.setImgSelected(props.product.photos[0]);
                        props.setModalProduct(true);
                    }}
                    loading="lazy"
                />
                {props.product.datasheet[20] === true ? (
                    <img className="product-photo product-photo-water" src={"/img/catalog/water2.gif"} />
                ) : (
                    <></>
                )}
                <CopyDiv className="product-sku" value={props.product.sku}>
                    {props.product.sku}
                </CopyDiv>
                <span
                    className={`product-line ${
                        new Date(props.product.createdAt).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7 || props.product.custom
                            ? "shown"
                            : ""
                    }`}
                >
                    {props.product.custom ? "Personalizável" : "Novo!"}
                </span>
                <span className="product-price">
                    R$ <span className="product-price-value">{props.product.price}</span>
                    <div className="product-suggested-price">
                        <img
                            className="config"
                            src="/img/config-icon.svg"
                            onClick={() => {
                                props.setModalObj(props.product);
                                props.setModalPrice(true);
                            }}
                        />
                        <div className="suggested-price">
                            <img src="/marketplaces/mercadolivre2.svg" />
                            <span>
                                <small>R$ </small>
                                {Math.round(
                                    calcPrice(
                                        1,
                                        props.product.price,
                                        props.percentual ? props.lucro / 100 || 0 : 0.4,
                                        props.percentual ? props.tax / 100 || 0 : 0,
                                        0
                                    )
                                )}
                            </span>
                        </div>
                        <div className="suggested-price">
                            <img src="/marketplaces/magalu5.svg" />
                            <span>
                                <small>R$ </small>
                                {Math.round(
                                    calcPrice(
                                        2,
                                        props.product.price,
                                        props.percentual ? props.lucro / 100 || 0 : 0.4,
                                        props.percentual ? props.tax / 100 || 0 : 0,
                                        0
                                    )
                                )}
                            </span>
                        </div>
                        <div className="suggested-price">
                            <img src="/marketplaces/amazon.svg" />
                            <span>
                                <small>R$ </small>
                                {Math.round(
                                    calcPrice(
                                        3,
                                        props.product.price,
                                        props.percentual ? props.lucro / 100 || 0 : 0.4,
                                        props.percentual ? props.tax / 100 || 0 : 0,
                                        0
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </span>
                <span
                    style={{ display: hasId ? "none" : "inline-block" }}
                    className="product-select-btn"
                    onClick={() => {
                        let newSelected = props.selected;
                        newSelected = [
                            {
                                id: props.product.id,
                                qtde: 1,
                                options: {},
                                // options: { boxQtty: 1 },
                            },
                            ...props.selected,
                        ];
                        props.setSelected(newSelected);
                        if (props.product.custom) {
                            let product = newSelected[newSelected.findIndex((e) => e.id == props.product.id)];

                            if (!product.options.custom) {
                                product.options.custom = true;
                                product.options.names = [];
                                props.setModalObj(product);
                                props.setModalBurn(true);
                            }
                        }
                    }}
                >
                    Adicionar
                </span>
                <span style={{ display: !hasId ? "none" : "inline-flex" }} className="product-qtty-container">
                    <span
                        className="trash"
                        style={{
                            display:
                                hasId && props.selected.filter((e) => e.id == props.product.id)[0].qtde > 1 ? "none" : "block",
                        }}
                        onClick={() => {
                            let newSelected = props.selected.filter((e) => e.id != props.product.id);
                            props.setSelected(newSelected);
                        }}
                    >
                        <img src="/img/tampalixeira.svg" />
                        <img src="/img/lixeira.svg" />
                    </span>
                    <span
                        className="product-minus"
                        style={{
                            display:
                                hasId && props.selected.filter((e) => e.id == props.product.id)[0].qtde > 1 ? "block" : "none",
                        }}
                        onClick={() => {
                            let newSelected = props.selected.slice();
                            let product = newSelected[newSelected.findIndex((e) => e.id == props.product.id)];

                            newSelected[newSelected.findIndex((e) => e.id == props.product.id)].qtde--;
                            if (product.options.box)
                                product.options.boxQtty = product.options.boxQtty <= 1 ? 1 : product.options.boxQtty - 1;
                            props.setSelected(newSelected);
                        }}
                    >
                        -
                    </span>
                    <div className="product-qtty">
                        {hasId ? props.selected.filter((e) => e.id == props.product.id)[0].qtde : 0}
                    </div>
                    <img
                        src="/img/mais.svg"
                        className="product-plus"
                        onClick={() => {
                            let newSelected = props.selected.slice();
                            let product = newSelected[newSelected.findIndex((e) => e.id == props.product.id)];
                            product.qtde++;
                            if (product.options.box) product.options.boxQtty++;
                            props.setSelected(newSelected);
                            console.log(product.options);
                        }}
                    />
                </span>
            </div>
        </div>
    );
}

export default ProductList;
