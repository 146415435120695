import React, { useEffect, useState, useRef, useContext, Fragment } from "react";
import "./AIstemChat.css";
import Einstein from "../Einstein/Einstein.js";
import { TypeAnimation } from "react-type-animation";

import { UserContext } from "../../App.js";
import { createRef } from "react";
import CopyDiv from "../CopyDiv/CopyDiv.js";
import { useNavigate } from "react-router-dom";
// import VoiceWidget from "../AIstem/AIstem.js";

import { aistemAddItem } from "../../pages/Catalog/Catalog.js";
import { getConfig } from "../../util/config.js";

function AIstemChat(props) {
    const navigate = useNavigate();

    const { user, setUser, orders, messages, setMessages, modals, setNewMessage, newMessage } =
        useContext(UserContext);

    const [beep4] = useState(new Audio("/AIstem/aistemsound4.mp3"));
    const [orderSound] = useState(new Audio("/AIstem/ordersound.mp3"));
    const [beerSound] = useState(new Audio("/AIstem/beersound2.mp3"));

    useEffect(() => {
        orderSound.volume = getConfig(user, "aistemVolume") / 100;
        beep4.volume = getConfig(user, "aistemVolume") / 100;
        beerSound.volume = getConfig(user, "aistemVolume") / 100;
    }, [user]);

    // user.screening = false;

    const [enableMessage, setEnableMessage] = useState(false);
    const [userMsg, setUserMsg] = useState("");
    const [waitingReply, setWaitingReply] = useState(null);

    const [userAddress, setUserAddress] = useState({});

    const msgInput = useRef(null);
    const messagesRef = useRef([]);

    const messagesEndRef = createRef(null);

    const waitReplyResolve = useRef(null);

    const messagesContainer = createRef(null);

    useEffect(() => {
        messagesRef.current = messages;
        // console.log(messages);
        // messagesContainer?.current?.scrollTo(0, messagesContainer?.current?.scrollHeight);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const playAudio = async (gptResponse) => {
        props.setSpeaking(true);
        // audio.src = `https://orizom.cloud${gptResponse.audio}`;
        props.audio.src =
            window.location.protocol === "http:" ? `http://192.168.18.2:5000${gptResponse.audio}` : `${gptResponse.audio}`;
        props.audio.volume = getConfig(user, "aistemVolume") / 100;

        let delay = 1000;

        if (gptResponse.type === "newOrder") {
            if (new Date().getDay() === 5 && getConfig(user, "beerNotification")) {
                beerSound.play().catch();
                delay = 5000;
            } else {
                orderSound.play().catch();
                delay = 1500;
            }
        } else {
            beep4.play().catch();
            delay = 1000;
        }

        setTimeout(() => {
            props.audio.play().catch();
        }, delay);

        props.audio.addEventListener("ended", function () {
            props.audio.currentTime = 0;
            props.setSpeaking(false);
            setTimeout(
                () => props.setLastMessage(null),
                ["close", "navigateTo"].includes(gptResponse?.function?.functionName) ? 2000 : 12000
            );
        });
    };

    useEffect(() => {
        if (newMessage) {
            console.log(newMessage.data);
            setNewMessage(null);
            playAudio(newMessage.data);
            props.setLastMessage(newMessage.data);
        }
    }, [newMessage]);

    useEffect(() => {
        if (waitingReply) {
            if (props.reply) {
                // messagesContainer?.current?.scrollTo(0, messagesContainer?.current?.scrollHeight);
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                props.setReply(null);
                setWaitingReply(false);
                props.setWaiting(true);
                setEnableMessage(false);
                msgInput.current.blur();

                waitReplyResolve.current(props.reply);

                waitReplyResolve.current = null;
                // props.message.resolve({
                //     message: gptMessage,
                //     reply: props.reply,
                // });
            }
        }
    }, [waitingReply, props.reply, props.setReply]);

    useEffect(() => {
        return () => {
            if (messagesRef.current.length) {
                setMessages([
                    ...messagesRef.current.map((e) => {
                        return { ...e, saved: true };
                    }),
                ]);
            }
        };
    }, []);

    async function saveScreening() {
        await fetch("/api/gpt/savescreening", {
            method: "POST",
            body: JSON.stringify({
                messages: messagesRef.current,
                address: userAddress,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    // function getGptFunctions(message, instructions) {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             // console.log(messagesRef.current);

    //             const response = await fetch("/api/gpt/createmessage", {
    //                 method: "POST",
    //                 body: JSON.stringify({
    //                     message: message,
    //                     instructions: instructions,
    //                     messages: messagesRef.current.slice(0, 15).reverse(),
    //                 }),
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             });

    //             const gptResponse = await response.json();
    //             await responseLoaded(gptResponse);

    //             resolve();
    //         } catch (err) {
    //             console.error(err);
    //             resolve(message);
    //         }
    //     });
    // }

    function getGpt(message, instructions, userMessage) {
        return new Promise(async (resolve, reject) => {
            try {
                // console.log(messagesRef.current);

                const response = await fetch("/api/gpt/createmessage", {
                    method: "POST",
                    body: JSON.stringify({
                        message: message,
                        instructions: instructions,
                        userMessage: userMessage,
                        userData: { minCredits: modals.current.minCredits },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                // throw new Error();

                const gptResponse = await response.json();

                try {
                    switch (gptResponse?.function?.functionName) {
                        case "openUrl":
                            document.elementFromPoint(1, 1).click();
                            window.open(gptResponse?.function?.url, "_blank").focus();
                            break;
                        case "goldSheet":
                            document.elementFromPoint(1, 1).click();
                            if (gptResponse?.function?.download) {
                                window.open(gptResponse?.function?.link_planilha, "_blank");
                            }
                            break;
                        case "addProduct":
                            document.elementFromPoint(1, 1).click();
                            navigate(`/produtos`);
                            setTimeout(() => {
                                document.elementFromPoint(1, 1).click();
                                aistemAddItem(
                                    gptResponse?.function?.id,
                                    gptResponse?.function?.qtty,
                                    gptResponse?.function?.removeAll
                                );
                            }, 1000);
                            
                            // navigate(`/produtos?add=${gptResponse?.function?.id}&qtty=${gptResponse?.function?.qtty || 1}`);
                            // alert(JSON.stringify(gptResponse?.function));
                            break;
                        case "navigateTo":
                            document.elementFromPoint(1, 1).click();
                            if (gptResponse?.function?.page)
                                switch (`${gptResponse?.function?.page || "produtos"}`) {
                                    case "chat":
                                        props.setAistemOpen(true);
                                        break;
                                    case "plano_de_voo":
                                        props.setFirstStepsModal(true);
                                        break;
                                    case "extrato":
                                        modals.current.setModalExtract(true);
                                        if (gptResponse?.function?.download)
                                            setTimeout(() => {
                                                document.getElementsByClassName("extract-download-button")[0]?.click();
                                            }, 2000);
                                        break;
                                    case "configuracoes":
                                        modals.current.setModalConfig(true);
                                        break;
                                    case "minha_conta":
                                        modals.current.setModalAccount(true);
                                        break;
                                    default:
                                        navigate(`/${gptResponse?.function?.page || "produtos"}`);
                                }
                            break;
                        case "close":
                            document.elementFromPoint(1, 1).click();
                            break;
                        case "openOrder":
                            document.elementFromPoint(1, 1).click();
                            let order = Object.values(orders).find((o) => o.id === gptResponse?.function?.order);
                            if (order) {
                                navigate("pedidos");
                                setTimeout(() => {
                                    modals.current.setModalOrderObj(order);
                                    modals.current.setModalOrder(true);
                                    if (gptResponse?.function?.plp || gptResponse?.function?.nfe) {
                                        setTimeout(() => {
                                            if (gptResponse?.function?.plp) {
                                                modals.current.setModalPlp(true);
                                            } else {
                                                modals.current.setModalNfe(true);
                                            }
                                        }, 300);
                                    }
                                }, 200);
                            }
                            break;
                        case "insertCredits":
                            if (gptResponse?.function?.value >= modals.current.minCredits) {
                                document.elementFromPoint(1, 1).click();
                                modals.current.setWalletValue(gptResponse?.function?.value);
                                modals.current.setModalWallet(gptResponse?.function?.pix ? 2 : 1);
                            }
                            // alert("inseridossdasdd");
                            break;
                        default:
                            break;
                    }
                } catch {}
                setTimeout(
                    async () => {
                        try {
                            props.setLastMessage(gptResponse);
                            resolve(gptResponse);
                            props.resetTranscript();
                            if (gptResponse.audio) {
                                await playAudio(gptResponse);
                            }
                        } catch {
                            props.setSpeaking(false);
                            setTimeout(() => props.setSpeaking(false), 2000);
                        }
                    },
                    gptResponse?.function?.functionName === "addProduct" ? 3500 : 0
                );
            } catch (err) {
                console.error(err);
                resolve(
                    message || {
                        response: `${
                            user.name.split(" ")[0]
                        }, eu peço desculpas, parece que eu estou com alguns problemas para me conectar ao banco de dados da IA nesse momento... Poderia tentar novamente daqui a pouco?`,
                    }
                );
            }
        });
    }

    async function addMessage(message) {
        await setMessages([
            {
                id: Date.now(),
                createdAt: Date.now(),
                loading: message.type === "einstein" ? true : false,
                ...message,
            },
            ...messagesRef.current,
        ]);
    }

    function newAistemMessage(message, userMessage) {
        return new Promise(async (resolve, reject) => {
            const messageId = Date.now();

            await setMessages([
                {
                    id: messageId,
                    createdAt: Date.now(),
                    type: "einstein",
                    loading: true,
                },
                ...messagesRef.current,
            ]);

            const gptResponse = message?.content
                ? { response: message?.content }
                : await getGpt(message?.content, null, userMessage);

            let newMessages = messagesRef.current;
            newMessages = newMessages.filter((e) => e.id !== messageId);
            await setMessages([
                {
                    ...(message || {}),
                    id: messageId,
                    type: "einstein",
                    content: gptResponse.response,
                    function: gptResponse.function,
                    createdAt: Date.now(),
                    resolve: resolve,
                },
                ...newMessages,
            ]);
            // setTimeout(() => resolve(), 500);
        });
    }

    async function responseLoaded(message) {
        return;
        let newMessages = messagesRef.current;
        let oldMessage = messagesRef.current.find((e) => e.loading);
        newMessages = newMessages.filter((e) => !e.loading);
        await setMessages([
            { ...oldMessage, loading: false, content: message.response, function: message.function },
            ...newMessages,
        ]);
    }

    async function screeningOld() {
        await newAistemMessage({
            content: `E aí ${user.name?.split(" ")[0]}, beleza!?
Seja muito bem-vindo de volta! Porém, agora na nossa mais nova plataforma!
Sou o AI-stem, a nova inteligência artificial da Orizom. Tenho só 1 ano de idade, então posso cometer falhas, pois ainda estou aprendendo.
Mas estou em um processo evolutivo constante, e com sua ajuda, irei aprender muito!`,
        });

        await newAistemMessage({
            content: `Ah, e caso precise navegar pela velha plataforma, para coletar alguns dados importantes, basta clicar no botão abaixo! Basta fazer login normalmente, mas você não consegue mais realizar pedidos por lá!`,
            links: [
                {
                    text: "Plataforma antiga",
                    url: "https://orizombackup.com.br/",
                },
            ],
        });

        let text = [
            "Sim! Responderei tudo de forma real sobre meu perfil!",
            "Com certeza! Vou jogar a real sobre o meu perfil.",
            "Bora lá! Tô aberto a falar a real sobre o meu perfil",
        ][Math.floor(3 * Math.random())];

        await newAistemMessage({
            content: `Sei que você já possui alguma expertise com a gente pela plataforma antiga, mas com meu auxílio nessa nova fase da Orizom, criarei um plano de voo para você que dará uma objetividade maior ao nosso desenvolvimento!
            Pra isso, preciso conhecer um pouco mais sobre você. O que você fornecer como resposta será utilizado para criação dessa rota que tem como objeto otimizar e buscar o caminho mais rápido. Tudo bem?`,
            buttons: [
                {
                    text: text,
                    action: () => {
                        screeningOld_1(text);
                    },
                },
            ],
        });
    }

    async function screeningOld_1(text) {
        await addMessage({
            type: "user",
            content: text,
        });

        await newAistemMessage(
            {
                content: `Para darmos continuidade em nossa jornada, pode me dizer qual é o seu maior objetivo com a nossa parceria?`,
            },
            text
        );

        let reply = await waitReply();

        await newAistemMessage(
            {
                content: `Show! Acabei de liberar todas as funções da plataforma para você!
Eu também criei um botão chamado "Plano de voo" aqui embaixo, que vai te ajudar a dar continuidade em sua jornada com a Orizom!
Estou aqui o tempo todo para te ajudar com qualquer coisa! Se precisar de algo, é só mandar uma mensagem aqui! 🚀`,
            },
            reply
        );

        let newUser = { ...user };
        newUser.screening = true;
        setUser(newUser);
        saveScreening();
        chat();
    }

    async function screening() {
        await newAistemMessage({
            content: `Olá ${user.name?.split(" ")[0]}, beleza!?
Seja bem-vindo à Orizom!
Antes de mais nada, muito bom ter você como um Franqueado! Eu sou o AI-stem, a Inteligência Artificial da Orizom.
Tenho 1 ano de idade, então às vezes posso cometer falhas pois estou em modo Beta, mas utilizando o banco de dados da Orizom irei te ajudar em quase tudo!
Estou em processo evolutivo constante e irei aprender muito com seu perfil de vendedor bem como com o Mercado!`,
        });

        let text = [
            "Sim! Responderei tudo de forma real sobre meu perfil!",
            "Com certeza! Vou jogar a real sobre o meu perfil.",
            "Bora lá! Tô aberto a falar a real sobre o meu perfil",
        ][Math.floor(3 * Math.random())];

        await newAistemMessage({
            content: `Vamos ao que interessa!
Para que eu crie um "plano de voo" que nos dê o máximo de eficiência possível, preciso conhecer um pouco sobre você! 
O que você fornecer como resposta a tudo que eu precisar, será utilizado para criação dessa rota que tem como objeto otimizar e buscar o caminho mais rápido ao nosso objetivo primário: Evolução da nossa parceiria juntos ok?`,
            buttons: [
                {
                    text: text,
                    action: () => {
                        screening1_1(text);
                    },
                },
            ],
        });
    }

    async function screening1_1(text) {
        await addMessage({
            type: "user",
            content: text,
        });
        await screening_cep("Vamos lá! Pode me dizer o seu CEP?", text);
    }

    async function screening_cep(text, ureply) {
        await newAistemMessage(
            {
                content: text,
            },
            ureply
        );

        let res = await waitReply();

        let replyCep = res.replace(/\D/g, "");

        if (replyCep.length !== 8) {
            await screening_cep("Esse não é um CEP válido! Tem certeza que digitou corretamente? Preciso de um CEP válido.", res);
            return;
        }

        const viaCep = await fetch(`https://viacep.com.br/ws/${res}/json/`);

        const cepJson = await viaCep.json();

        if (cepJson.error) {
            await screening_cep("Esse não é um CEP válido! Tem certeza que digitou corretamente? Preciso de um CEP válido.", res);
            return;
        }

        setUserAddress(cepJson);

        await newAistemMessage(
            {
                content: `Show! O endereço que eu encontrei aqui foi: 
${cepJson.logradouro}
${cepJson.bairro}
${cepJson.localidade} - ${cepJson.uf}

Está correto, ou deseja trocar de CEP?`,
                buttons: [
                    {
                        text: "Está correto!",
                        action: async () => {
                            await addMessage({
                                type: "user",
                                content: "Está correto!",
                            });
                            await screening_housenumber();
                        },
                    },
                    {
                        text: "Não, desejo corrigir",
                        action: async () => {
                            await addMessage({
                                type: "user",
                                content: "Não, desejo corrigir",
                            });
                            await screening_cep("Ok, então vamos lá, poderia me dizer o CEP correto?");
                        },
                    },
                ],
            },
            res
        );
    }

    async function screening_housenumber() {
        await newAistemMessage({
            content: `Maravilha! Agora que tenho seu CEP, pode me dizer o número da casa e complemento (se tiver)?`,
        });

        let res = await waitReply();

        setUserAddress({ ...userAddress, numero: res });
        await screening1();
    }

    async function screening1() {
        await newAistemMessage({
            content: `Legal! Só faltam mais algumas perguntas.
Qual seu nível de experiência vendendo em Marketplaces (Mercado Livre, Shopee, Magalu...)?`,
            buttons: [
                {
                    text: "Já vendo em marketplaces",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Já vendo em marketplaces`,
                        });
                        await screeningRoute1();
                    },
                },
                {
                    text: "Nunca vendi em marketplaces",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Nunca vendi em marketplaces`,
                        });
                        await screeningRoute2();
                    },
                },
            ],
        });
    }

    async function screeningRoute1() {
        await newAistemMessage({
            content: `Cite todos os marketplaces em que você vende ou já vendeu!`,
        });

        let res = await waitReply();

        let userPlaces = res.toLowerCase();

        let magalu = ["magalu", "magazine luiza", "magazine"].some((e) => userPlaces.includes(e));
        let netshoes = ["netshoes"].some((e) => userPlaces.includes(e));

        if ((magalu || netshoes) && user?.doc && user?.doc?.length < 13) {
            await newAistemMessage(
                {
                    content: `Vi que você disse que vende na ${
                        magalu ? "magalu" : "netshoes"
                    }, porém seu cadastro na orizom foi feito como CPF! Depois, entre em contato conosco e corrija essa informação, viu?`,
                },
                res
            );
        }

        if (["mercado livre", "mercadolivre", "ml", "mercadolibre", "mercado libre"].some((e) => userPlaces.includes(e))) {
            await newAistemMessage(
                {
                    content: `Você tem o termometro (reputação) ativo no Mercado Livre?`,
                    buttons: [
                        {
                            text: "Sim!",
                            action: async () => {
                                await addMessage({
                                    type: "user",
                                    content: `Sim!`,
                                });
                                await screeningRoute1P2();
                            },
                        },
                        {
                            text: "Ainda não",
                            action: async () => {
                                await addMessage({
                                    type: "user",
                                    content: `Ainda não`,
                                });
                                await screeningRoute1P2();
                            },
                        },
                    ],
                },
                res
            );
        } else {
            await screeningRoute1P2();
        }
    }

    async function screeningRoute1P2() {
        await newAistemMessage({
            content: `Já teve experiência vendendo utilizando uma empresa de dropshipping?`,
            buttons: [
                {
                    text: "Sim",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Sim`,
                        });
                        await screeningRoute1P3();
                    },
                },
                {
                    text: "Nunca",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Nunca`,
                        });
                        await screeningRoute1P6();
                    },
                },
            ],
        });
    }

    async function screeningRoute1P3() {
        await newAistemMessage({
            content: `Qual o nome da empresa de dropshipping que você já usou?`,
        });

        await waitReply();

        await newAistemMessage({
            content: `Você ainda utiliza essa empresa até hoje?`,
            buttons: [
                {
                    text: "Sim",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Sim`,
                        });
                        await screeningRoute1P4();
                    },
                },
                {
                    text: "Não",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: `Não`,
                        });
                        await screeningRoute1P5();
                    },
                },
            ],
        });
    }

    async function screeningRoute1P4() {
        await newAistemMessage({
            content: `Há quanto tempo você trabalha com essa empresa?`,
        });
        await waitReply();

        await newAistemMessage({
            content: `Quanto já faturou com essa empresa desde o início`,
        });
        await waitReply();

        await newAistemMessage({
            content: `O que você destacaria como positivo nessa empresa que poderia ajudar a Orizom no seu desenvolvimento como nosso franqueado?`,
        });
        await waitReply();

        await screeningRoute1P6();
    }

    async function screeningRoute1P5() {
        await newAistemMessage({
            content: `Você poderia destacar o fator que fez você querer parar de trabalhar com essa empresa?`,
        });
        await waitReply();

        await screeningRoute1P6();
    }

    async function screeningRoute1P6() {
        await newAistemMessage({
            content: `Qual é a sua ocupação atualmente?`,
        });

        await waitReply();

        await newAistemMessage({
            content: `Chegamos ao final! Todas suas respostas estão corretas, ou você gostaria de corrigir alguma coisa?`,
            buttons: [
                {
                    text: "Confirmo! Tudo certo",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: "Confirmo! Tudo certo",
                        });
                        screeningRoute1P7();
                    },
                },
                {
                    text: "Não, gostaria de refazer",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: "Não, gostaria de refazer",
                        });
                        setTimeout(() => {
                            setMessages([]);
                            screeningRoute1();
                        }, 1000);
                    },
                },
            ],
        });
    }

    async function screeningRoute1P7() {
        await newAistemMessage({
            content: `Obrigado por compartilhar todas essas informações comigo!

Meu diagnostico preliminar é: 

Precisamos fazer a conexão do Bling (ERP que a Orizom trabalha) para automatizar todo o processo de vendas (onde eu puxo todos as vendas que você fizer) bem como eu subir automaticamente todos os anúncios e novas ofertas que a Orizom disponibilizar em sua conta!
Além disso com a automação, eu consigo ser mais previsível pois terei mais dados para coletar e assim ser mais acertivo nas otimizações que farei ao longo da nossa jornada!
Caso você ainda não utilize o bling, disponibilizarei um cupom de desconto de 6 meses do Bling para voce não precisar pagar logo de cara!`,
            links: [
                { text: "Copiar o cupom", copy: "#vendedorindicado" },
                { text: "Acessar o bling", url: "https://bling.com.br/" },
            ],
        });
        await newAistemMessage({
            content: `Acabei de liberar todas as funções da plataforma para você!
Eu também criei um botão chamado "Plano de voo" aqui embaixo, que vai te ajudar nos primeiros passos da sua jornada com a Orizom!
Estou aqui o tempo todo para te ajudar com qualquer coisa! Se precisar de algo, é só mandar uma mensagem aqui! 🚀`,
        });

        let newUser = { ...user };
        newUser.screening = true;
        setUser(newUser);
        saveScreening();

        await chat();
    }

    async function screeningRoute2() {
        await newAistemMessage({
            content: `Entendi!
Atualmente você trabalha em qual ocupação?
Seja bem específico, ok?`,
        });
        let res = await waitReply();

        await screeningRoute2P2(res);
    }

    async function screeningRoute2P2(res) {
        if (res.length < 10) {
            await newAistemMessage({
                type: "einstein",
                content: "Essa resposta foi um pouco curta... Você poderia ser um pouco mais específico?",
            });

            let newRes = await waitReply();
            screeningRoute2P2(newRes);
            return;
        }
        await newAistemMessage({
            content: `Quantas horas você tem disponível todos os dias para seu projeto da franquia?`,
            buttons: [
                {
                    text: "1 hora",
                    action: () => screeningRoute2P3("Tenho 1 hora"),
                },
                {
                    text: "2 horas",
                    action: () => screeningRoute2P3("Tenho 2 horas"),
                },
                {
                    text: "3 horas",
                    action: () => screeningRoute2P3("Tenho 3 horas"),
                },
                {
                    text: "+ 4 horas",
                    action: () => screeningRoute2P3("Tenho 4 horas ou mais"),
                },
            ],
        });
    }

    async function screeningRoute2P3(text) {
        await addMessage({
            type: "user",
            content: text,
        });
        await newAistemMessage({
            content: `Em qual parte do dia?`,
            buttons: [
                {
                    text: "Manhã",
                    action: () => screeningRoute2P4("Manhã"),
                },
                {
                    text: "Tarde",
                    action: () => screeningRoute2P4("Tarde"),
                },
                {
                    text: "Noite",
                    action: () => screeningRoute2P4("Noite"),
                },
            ],
        });
    }

    async function screeningRoute2P4(text) {
        await addMessage({
            type: "user",
            content: text,
        });

        await newAistemMessage({
            content: `Qual seu maior objetivo nesse mundo das vendas online?`,
        });
        await waitReply();

        await newAistemMessage({
            content: `Por fim, na sua visão, o que voce viu de diferencial que a Orizom tem que pode nos ajudar chegar nesse objetivo?`,
        });
        await waitReply();

        await newAistemMessage({
            content: `Chegamos ao final! Todas suas respostas estão corretas, ou você gostaria de corrigir alguma coisa?`,
            buttons: [
                {
                    text: "Confirmo! Tudo certo",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: "Confirmo! Tudo certo",
                        });
                        screeningRoute2P5();
                    },
                },
                {
                    text: "Não, gostaria de refazer",
                    action: async () => {
                        await addMessage({
                            type: "user",
                            content: "Não, gostaria de refazer",
                        });
                        setTimeout(() => {
                            setMessages([]);
                        }, 1000);
                    },
                },
            ],
        });
    }

    async function screeningRoute2P5() {
        if (user?.doc && user?.doc?.length < 13)
            await newAistemMessage({
                content: `Muito obrigado por partilhar um pouco sobre você!
Agora consigo iniciar minha analise para formar uma rota mais "acertiva" para nosso percurso até a chegada do seu objetivo!

Meu diagnóstico preliminar é:

Precisamos estudar muito sobre Mercado Livre e Shopee, 2 canais que pelo seu perfil (vendedor CPF) é aceito para iniciar!
Desta forma estou disponibilizando 3 playlists que serão "divisores de água" para o conforto da sua fase inicial!`,
                links: [
                    {
                        text: "Playlist Mercado Livre",
                        url: "https://www.youtube.com/results?search_query=mercado+livre+para+iniciantes",
                    },
                    {
                        text: "Playlist Shopee",
                        url: "https://www.youtube.com/results?search_query=Shopee+para+iniciantes",
                    },
                    {
                        text: "Playlist Bling",
                        url: "https://www.youtube.com/@BlingBr/videos",
                    },
                ],
            });

        await newAistemMessage({
            content: `Disponibilizamos um cupom de desconto de 6 meses grátis do Bling!

O plano ideal para seu perfil é o plano: "Cobalto"`,
            links: [
                { text: "Copiar o cupom", copy: "#vendedorindicado" },
                { text: "Acessar o bling", url: "https://www.bling.com.br/" },
            ],
        });

        await newAistemMessage({
            content: `Acabei de liberar todas as funções da plataforma para você!
Eu também criei um botão chamado "Plano de voo" aqui embaixo, que vai te ajudar nos primeiros passos da sua jornada com a Orizom!
Estou aqui o tempo todo para te ajudar com qualquer coisa! Se precisar de algo, é só mandar uma mensagem aqui! 🚀`,
        });

        let newUser = { ...user };
        newUser.screening = true;
        setUser(newUser);
        saveScreening();

        await chat();
    }

    async function waitReply() {
        return new Promise((resolve, reject) => {
            setEnableMessage(true);
            msgInput?.current?.focus();
            setWaitingReply(true);
            props.setWaiting(false);
            waitReplyResolve.current = resolve;
        });
    }

    async function chat() {
        let userMessage = await waitReply();
        await newAistemMessage(null, userMessage);
        // await getGptFunctions();
        await chat();
    }

    useEffect(() => {
        if (user.screening) {
            // setEnableMessage(true);
            // msgInput.current.focus();
            // setWaitingReply(true);
            chat();
        } else {
            if (user.oldId) screeningOld();
            else screening();
            props.setAistemOpen(true);
        }
    }, []);

    // useEffect(() => {
    //     if (waitingReply && reply) {
    //         messagesContainer?.current?.scrollTo(0, messagesContainer?.current?.scrollHeight);
    //         chat();
    //         setReply(null);
    //         setWaitingReply(false);
    //     }
    // }, [reply, waitingReply]);

    return (
        <>
            {/* <VoiceWidget
                onClick={() => {
                    setAistemModal(true);
                    setLastMessage(null);
                }}
                addMessage={addMessage}
                setReply={setReply}
                waitingReply={waitingReply}
                audio={audio}
                lastMessage={lastMessage}
                setLastMessage={setLastMessage}
                speaking={speaking}
            /> */}

            <div className="welcome" onClick={(ev) => ev.stopPropagation()}>
                <div className="einstein-dialog" ref={messagesContainer}>
                    <div ref={messagesEndRef} />
                    {messages.map((message, i) => {
                        let date = new Date(message.createdAt || "");

                        let dateDisplay = "";
                        let showDate = false;

                        let now = new Date();

                        if (date.getDate() === now.getDate()) dateDisplay = "Hoje";
                        now.setDate(now.getDate() - 1);
                        if (date.getDate() === now.getDate()) dateDisplay = "Ontem";

                        if (!dateDisplay) {
                            const yyyy = date.getFullYear();
                            const mm = `${date.getMonth() + 1}`.padStart(2, "0");
                            const dd = `${date.getDate()}`.padStart(2, "0");
                            dateDisplay = `${dd}/${mm}/${yyyy}`;
                        }

                        if (messages[i + 1]) {
                            if (new Date(messages[i + 1].createdAt).getDate() !== date.getDate()) {
                                showDate = true;
                            }
                        } else {
                            showDate = true;
                        }

                        return (
                            <Fragment key={message.id}>
                                <Message
                                    message={message}
                                    messages={messages}
                                    setMessages={setMessages}
                                    user={user}
                                    setFullImage={props.setFullImage}
                                    i={i}
                                    messagesEndRef={messagesEndRef}
                                />
                                {showDate ? <div className="message-date-separator">{dateDisplay}</div> : <></>}
                            </Fragment>
                        );
                    })}
                </div>
                <div className={`einstein-chat-input ${enableMessage ? "enabled" : ""}`}>
                    <div className="einstein-message-input">
                        <div
                            className={`first-steps-open ${user.screening ? "shown" : ""}`}
                            onClick={() => props.setFirstStepsModal(true)}
                        >
                            Plano de voo
                        </div>
                        <input
                            ref={msgInput}
                            placeholder="Digite sua resposta aqui..."
                            value={userMsg}
                            onChange={(ev) => {
                                setUserMsg(ev.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.code === "Enter" || ev.code === "NumpadEnter") {
                                    ev.preventDefault();
                                    if (!userMsg.trim()) return;
                                    setUserMsg("");
                                    addMessage({
                                        type: "user",
                                        content: userMsg,
                                    });
                                    props.setReply(userMsg);
                                }
                            }}
                        />
                        <img
                            className={`aistem-send ${userMsg.trim() ? "active" : ""}`}
                            src="/AIstem/send.svg"
                            alt="Enviar mensagem para o AI-stem"
                            onClick={() => {
                                if (!userMsg.trim()) return;
                                setUserMsg("");
                                addMessage({
                                    type: "user",
                                    content: userMsg,
                                });
                                props.setReply(userMsg);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

function Message(props) {
    const [showButtons, setShowButtons] = useState(false);

    const clickable = props.messages[0]?.id === props.message.id;

    const messageContainerRef = createRef(null);

    let links, images, content;

    if (props?.message?.content) {
        let messageLinks = [...props.message.content?.matchAll(/(?=[^!]\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi)];
        let messageImages = [...props.message.content?.matchAll(/(?=!\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi)];

        if (messageLinks?.length)
            links = messageLinks.map((e) => {
                return {
                    text: e[1],
                    url: e[2],
                };
            });

        if (messageImages?.length)
            images = messageImages.map((e) => {
                return {
                    text: e[1],
                    url: e[2],
                };
            });

        content = props.message.content.replace(/!?\[([^\[\]]*)\]\((.*?)\)\.?/gm, "");
        // content = content.replace(/\d+\s*[-\\.)]?\s+/gi, "");
        // content = content.replace(/(\【[.]*\】)/g, "");
        content = content.replace(/ *\【[^】]*\】\.? */g, "");
        content = content.replace(/\n\n+/g, "\n");
        content = content.replace(/\**/g, "");
        // content = props.message.content;
    }

    // useEffect(() => {
    //     console.log(messageContainerRef?.current?.offsetHeight);
    // }, [messageContainerRef?.current?.offsetHeight]);

    useEffect(() => {
        if (!messageContainerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            props.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            // console.log(messageContainerRef?.current?.offsetHeight);
        });
        resizeObserver.observe(messageContainerRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    return (
        <div
            style={{ transitionDelay: `${props.i * 100 + 600}ms` }}
            className={`einstein-dialog-row ${props.message.saved ? "shown" : "shown"} dialog-${props?.message?.type}`}
            ref={messageContainerRef}
        >
            {props?.message?.type === "einstein" ? (
                <Einstein className="welcome-einsten" start={!props.message.loading} alreadyStarted={props.message?.saved} />
            ) : (
                <img
                    src={
                        props.user.photo
                            ? window.location.protocol === "http:"
                                ? `https://orizom.com.br/${props.user.photo}`
                                : props.user.photo
                            : "/img/default-icon.svg"
                    }
                />
            )}
            <div className="einstein-dialog-row-text">
                {props.message.saved || props.message.type === "user" ? <span>{content}</span> : <></>}
                {!props.message.loading && !props.message.saved && props.message.type !== "user" ? (
                    <TypeAnimation
                        key={content}
                        sequence={[
                            400,
                            content,
                            100,
                            () => {
                                setShowButtons(true);
                                if (props.message.onLoad) {
                                    props.message.onLoad();
                                }
                                if (props.message.resolve) props.message.resolve();
                            },
                        ]}
                        speed={{
                            type: "keyStrokeDelayInMs",
                            value: 2,
                        }}
                    />
                ) : (
                    <></>
                )}
                {props.message?.buttons ? (
                    <div className={`einstein-dialog-buttons`}>
                        {props.message?.buttons?.map((button, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`einstein-button ${showButtons || props.message?.saved ? "shown" : ""}

                                    ${clickable ? "clickable" : ""}`}
                                    onClick={button.action}
                                >
                                    {button.text}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
                {!images && props.message?.function && props.message?.function?.produtos_buscados?.some((e) => e.photo_url) ? (
                    <div className={`message-product-photos ${showButtons || props.message.saved ? "shown" : ""}`}>
                        {props.message?.function?.produtos_buscados.map((p, i) => {
                            if (!p?.photo_url) return;
                            return (
                                <div
                                    className="message-product-photo"
                                    key={i}
                                    style={{
                                        transitionDelay: `${200 * (i + 1)}ms`,
                                    }}
                                >
                                    <div className="message-product-sku">{p.sku}</div>
                                    <img src={`${p.photo_url}`} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}
                {props.message?.links || links ? (
                    <div className={`einstein-dialog-links`}>
                        {[...(props.message?.links || []), ...(links || [])].map((button, i) => {
                            if (button.copy) {
                                return (
                                    <CopyDiv
                                        key={i}
                                        value={button?.copy}
                                        className={`einstein-button ${
                                            showButtons || props.message?.saved ? "shown" : ""
                                        } clickable`}
                                    >
                                        {button?.text}
                                    </CopyDiv>
                                );
                            } else {
                                return (
                                    <a
                                        key={i}
                                        href={button.url}
                                        target="_blank"
                                        className={`einstein-button ${
                                            showButtons || props.message.saved ? "shown" : ""
                                        } clickable`}
                                    >
                                        {button.text}
                                    </a>
                                );
                            }
                        })}
                    </div>
                ) : (
                    <></>
                )}

                {images ? (
                    <div className={`einstein-dialog-images ${showButtons || props.message.saved ? "shown" : ""}`}>
                        {images.map((button, i) => {
                            return (
                                <div className="einstein-image" onClick={() => props.setFullImage(button.url)} key={i}>
                                    <div className="einstein-image-overlay">Expandir</div>
                                    <img key={i} src={button.url} alt={button.text} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}

                {props.message.createdAt && (showButtons || props.message.saved || props.message.type === "user") ? (
                    <div className="message-time">{new Date(props.message.createdAt).toLocaleTimeString().slice(0, 5)}</div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default AIstemChat;
